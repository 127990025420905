import * as bootstrap from 'bootstrap';
import Swal from 'sweetalert2';
import './scss/vendor.scss';
import './scss/app.scss';

window.bootstrap = bootstrap;

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

var csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrftoken);
    }
  },
});

window.initTooltips = function () {
  $('[data-bs-toggle="tooltip"]').tooltip();
};

$(function () {
  initTooltips();
  $('[data-prompt="delete"]').on('click', promptDelete);
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-outline-dark m-2',
  },
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  icon: 'success',
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  // timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

window.promptDelete = function (formId, type, deleteFormValue) {
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this ' + type + '.',
      icon: 'warning',
      buttons: [true, 'Delete'],
      dangerMode: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        $(formId)
          .append(
            `<input type="hidden" name="delete" ${
              deleteFormValue ? `value=${deleteFormValue}` : ''
            }>`
          )
          .submit();
      }
    });
};

window.Swal = swalWithBootstrapButtons;
window.toast = Toast;

window.debounceLeading = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
};

window.debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

window.validateFileSize = function validateFileSize(element) {
  var $this = $(element);
  var filename = $this.val().replace(/C:\\fakepath\\/i, '');
  var maxFileSize = 100000000;
  var currentFile = $this.parent().siblings('.current-file');

  if ($this.get(0).files.length > 0) {
    var fileSize = $this.get(0).files[0].size;
  }

  if (fileSize == 0) {
    currentFile.text('File is empty.');
    currentFile.addClass('red');
  } else if (fileSize > maxFileSize) {
    currentFile.text(
      'File too large. Please select a file smaller than ' +
        maxFileSize / 1000000 +
        'MB'
    );
    currentFile.addClass('red');
  } else {
    currentFile.text(filename);
    currentFile.removeClass('red');
    return true;
  }
};

$(document).ready(function () {
  $('#client-search-input').on('keyup', function () {
    var value = this.value.toLowerCase();
    $('.searchable').filter(function () {
      var $this = $(this);
      $this.toggle($this.text().toLowerCase().indexOf(value) > -1);
    });
  });

  var input = document.getElementById('company-search-nav');
  if (input) {
    input.addEventListener('keyup', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        searchCompaniesNav();
      }
    });
  }
});

$('.modal').on('shown.bs.modal', function () {
  $(this).find('[autofocus]').focus();
});

function initTinyMce(options) {
  const content_style =
    '#tinymce { font-size: 16px; } td#specificity p, td#specificity ul li { line-height: 1.5; }';
  tinymce.remove(options.selector);
  tinymce.init({
    elementpath: false,
    branding: false,
    promotion: false,
    base_url: '/static/tinymce/',
    suffix: '.min',
    urlconverter_callback: function (url, node, on_save, name) {
      // This is needed, otherwise TinyMCE messes up our urls
      return url;
    },
    menubar: false,
    toolbar:
      'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | code',
    plugins: 'table lists code',
    content_style: content_style,
    statusbar: true,
    content_css: '/static/revroad/css/tinymce.css',
    ...(options || {}),
  });
}

window.loadTinyMce = function (options) {
  if (typeof tinymce === 'undefined') {
    $.ajax({
      url: __urlTinyMce,
      dataType: 'script',
      cache: true,
    }).done(function () {
      initTinyMce(options);
    });
    return true;
  } else {
    initTinyMce(options);
  }
};

window.showLoadingModal = function (message) {
  if (!$('#loading-modal')[0]) {
    $('body').append(`
    <div class="loading-modal" id="loading-modal">
      <div class="inner">
        <i class="fas fa-spinner fa-pulse"></i> <span id="loading-modal-text">Loading, please wait...</span>
      </div>
    </div>`);
  }
  if (message) {
    $('#loading-modal-text').text(message);
  }
  $('#loading-modal').fadeIn();
};

window.hideLoadingModal = function () {
  $('#loading-modal').fadeOut();
};

const $mobileNav = $('#mobile-nav');
const $mobileNavToggler = $('#mobile-nav-toggler');

$mobileNavToggler.on('click', () => {
  $mobileNav.toggleClass('show');
  $mobileNavToggler.toggleClass('collapsed');
  // console.log('mobileNavToggler clicked', $mobileNav.hasClass('show'));
  if ($mobileNav.hasClass('show')) {
    // Close the menu when clicking outside of it
    $(document).on('click.navToggler', (e) => {
      // console.log('document clicked');
      if (
        $mobileNav.hasClass('show') &&
        !$mobileNav.is(e.target) &&
        !$mobileNavToggler.is(e.target) &&
        $mobileNav.has(e.target).length === 0 &&
        $mobileNavToggler.has(e.target).length === 0
      ) {
        // console.log('removing show class');
        $mobileNav.removeClass('show');
        $mobileNavToggler.addClass('collapsed');
        $(document).off('click.navToggler');
        e.preventDefault();
        e.stopPropagation();
      }
    });
  } else {
    $(document).off('click.navToggler');
  }
});
